<template>
  <div>
    <AddHeaderTitle :Header="Header" />
    <AppHeaderInfoAdminList :infos="infos" />

    <b-row align-h="start">
      <b-button
        variant="primary "
        class="mb-2 mr-2"
        v-b-modal.modal-survey-create
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-left">Créer un survey </span>
      </b-button>
    </b-row>

    <b-table striped hover :items="list" :fields="fields">
      <template #cell(speakers)="list">
        <li v-for="speaker in list.item.speakers" :key="speaker.name">
          {{ speaker.name }}
        </li>
      </template>

      <template #cell(action)="list">
        <b-link
          :to="{ name: 'app-survey-detail', params: { id: list.item.id } }"
        >
          <b-button variant="flat-primary" size="sm"
          > 
            <feather-icon icon="EditIcon" />
             
          </b-button>
        </b-link>
        <b-button
          v-b-modal.modal-sm
          @click="sendInfo(list.item)"
          variant="flat-danger"
          class=""
          size="sm"
        >
          <feather-icon icon="Trash2Icon" />
          
        </b-button>
      </template>
    </b-table>

    <b-modal id="modal-sm" centered size="sm" title="Information">
      <template #default>
        Êtes-vous sûr de vouloir supprimer le survey {{ survey.name }} ?
      </template>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="primary" @click="cancel()">
          Annuler
        </b-button>
        <b-button size="sm" variant="danger" @click="deleteSurveyId(survey.id)">
          Supprimer
        </b-button>
      </template>
    </b-modal>

    <survey-create-modal @clicked="onCreate"> </survey-create-modal>
  </div>
</template>

<script>
import AddHeaderTitle from "@/app/views/components/AppHeaderTitle.vue";
import AppHeaderInfoAdminList from "@/app/views/components/AppHeaderInfoAdminList.vue";
import { BTable, BButton, BRow, BModal } from "bootstrap-vue";
import { BLink } from "bootstrap-vue";
import { Admin } from "@/mixins/admin.js";
import SurveyCreateModal from "./survey-create-modal.vue";

export default {
  name: "Surveys",

  components: {
    BTable,
    AddHeaderTitle,
    AppHeaderInfoAdminList,
    BButton,
    BLink,
    BRow,
    SurveyCreateModal,
    BModal,
  },

  data() {
    return {
      Header: {
        config: "Admin",
        listLink: "admin/survey/list",
        list: "Webinar-liste",
      },
      infos: {
        title: "Liste des surveys",
        content: "Texte descriptif ",
      },
      surveyData: {},
      survey: {},
      optionSpeaker: [],
      listSpeaker: [],
      statusArray: [
        {
          status: true,
          badge: "warning",
          text: "Actif",
        },
        {
          status: false,
          badge: "danger",
          text: "Inactif",
        },
      ],
      list: [],
      idSelect : "",
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        {
          key: "name",
          label: "Nom",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
        },
        {
          key: "Action",
          label: "Action",
          sortable: false,
        },
      ],
    };
  },

  mixins: [Admin],

  mounted() {
    this.loadList();
  },

  methods: {
    async loadList() {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
      });

      try {
        const response = await this.getAllSurveys();

        if (response.status == 1) {
          //this.surveyData = response.datas;
          if (response.datas) {
            this.list = response.datas;
          }
        } else {
          this.$sweetError("AF-52");
        }
        loader.hide();
      } catch {
        loader.hide();
        this.$sweetError("AF-52");
      }
    },

    onCreate(value) {
      this.list.push(value);
    },

    sendInfo(surveyInfo) {
      this.survey = surveyInfo;
    },

    async deleteSurveyId(id) {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
      });
      this.$bvModal.hide("modal-sm");
      try {
        const response = await this.deleteSurvey(id);
        if (response.status == 1) {
          this.$sweetNotif("Modification réussie");
          this.list = this.list.filter((survey) => survey.id !== id);
        } else {
          this.$sweetError("AF-51");
        }
        loader.hide();
      } catch {
        loader.hide();
        this.$sweetError("AF-51");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

